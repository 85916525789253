import {paymentService} from "../../services/PaymentApiService";
import {lookupService} from "../../services/LookupApiService";

export function payPCN(navigation: any, pcn: string, vrm: string, amount: number,
                             client: string, setPaymentSessionError: any, setDisableProceedPayment: any) {
    try {
        paymentService.getPaymentSession(pcn, vrm, amount, client).then((response: any) => {
            if (response.payByLinkUrl) {
                window.open(response.payByLinkUrl, '_self');
            } else {
                setDisableProceedPayment(false);
                setPaymentSessionError('There was a problem processing payment. Please try again later.')
            }
        })
    } catch (e) {
        setDisableProceedPayment(false);
        setPaymentSessionError('There was a problem processing payment. Please try again later.')
        console.error("Start Payment " + e);
    }
}

export async function doImagesLookup(values: any, navigation: any, myDomain: string, setImagesLookUpError: any, setHasImages: any, setArrayOfImages: any, setIsLoading:any) {
    let arrayOfImages: String[] = [];
    try {
        await lookupService.lookupImages(values.ticket, values.vrm.vrm, myDomain).then((response: any) => {
            setIsLoading(false);
            let images = response.images;
            if (response.status == 1) {
                for (let i = 0; i < images.length; i++) {
                    arrayOfImages.push(
                        "data:" + images[i].contentType + ";base64," + images[i].encodedImage
                    )
                }
                setArrayOfImages(arrayOfImages);
                setHasImages(true);
                setImagesLookUpError('')
            } else {
                setHasImages(false);
                setImagesLookUpError('There was a problem obtaining PCN images. Please try again later.')
            }
        })
    } catch (e) {
        setIsLoading(false);
        setHasImages(false);
        setImagesLookUpError('There was a problem obtaining PCN images. Please try again later.')
        console.error("Images Lookup " + e);
    }
}

export function formatDateForDisplay(str: string) {
        const year = str.substring(0, 4);
        const month = str.substring(5, 7);
        const date = str.substring(8, 10);

        const hour = str.substring(11, 13);
        const minute = str.substring(14, 16);
        const second = str.substring(17, 19);

        let toString = date + '-' + month + '-' + year + ' ' + hour + ':' + minute + ':' + second;

        return toString;
}