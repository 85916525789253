import {PaymentSessionRequest} from "../models/requests/PaymentSessionRequest";
import {apiService} from "./ApiService";
import Constants from "expo-constants";
import {InformPaymentRequest} from "../models/requests/InformPaymentRequest";
import {PaymentSessionResponse} from "../models/responses/PaymentSessionResponse";

const getPaymentSession = async (pcn: string, vrm: string, amount: number,
                                 client: string): Promise<PaymentSessionResponse | undefined> => {
    const request: PaymentSessionRequest = {
        pcn,
        vrm,
        amount,
        client,
    };
    return await apiService
        .doPost(Constants.manifest?.extra?.pcnApiUrl + '/web/paymentSession', request, {noAuthRequired: true});
};

const informPayment = async (pcn: string, client: string, reference: string): Promise<void> => {
    const request: InformPaymentRequest = {
        client,
        reference,
        pcn,
    };
    return await apiService
        .doPost(Constants.manifest?.extra?.pcnApiUrl + 'web/informPayment', request, {noAuthRequired: true});
};

export const paymentService = {
    getPaymentSession, informPayment
};