import React from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {AppStackParams} from "../../routes/AppStack";
import LookupResultView from "./LookupResultView";

type NavigationProps = NativeStackScreenProps<AppStackParams, 'LookupResult'>;

export const LookupResult = (props: NavigationProps) => {
    return (
        <LookupResultView route={{key: "LookupResult", name: "LookupResult"}}
                          navigation={props.navigation} response={props.route.params?.response}></LookupResultView>
    );
};

export default LookupResult;




