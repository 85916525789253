import * as Network from "expo-network";
import Constants from "expo-constants";
import {Platform} from "react-native";
import {NetworkState} from "expo-network/src/Network.types";

export interface BaseResponse {
    code: number;
    message: string;
}

export interface ApiOptions {
    noAuthRequired?: boolean;
    specificAuthToken?: string;
    extraTimeout?: boolean;
}

const doPost = async <T extends BaseResponse>(endpoint: string, data?: any, options?: ApiOptions): Promise<T | undefined> => {

    const networkState: NetworkState = await Network.getNetworkStateAsync();

    if (networkState.isConnected && networkState.isInternetReachable){
        return await run(endpoint, data, options);
    }else {
        throw new Error('No internet connection. Please review your network settings.');
    }
}

const doGetTextResponse = async (endpoint: string, options?: ApiOptions): Promise<string> => {

    const networkState: NetworkState = await Network.getNetworkStateAsync();

    if (!networkState.isConnected || !networkState.isInternetReachable){
        throw new Error('No internet connection. Please review your network settings.');
    }

    const headers: Headers = new Headers();
    headers.append('Accept', 'text/html');
    headers.append('Content-Type', 'text/html');

    let authRequired: boolean = true;
    let authToken: string = '';

    if (options != null) {
        if (options.noAuthRequired != null) authRequired = !options.noAuthRequired;
        if (options.specificAuthToken != null) authToken = options.specificAuthToken;
    }

    if (authRequired) {
        if (!authToken) {
            if (!Constants.manifest?.extra?.jwt) {
                const e = 'Authorised API call with no auth token supplied';
                console.error(e);
                throw new Error(e);
            } else {
                authToken = Constants.manifest?.extra?.jwt;
            }
        }
        headers.append('Bearer ', authToken);
    }

    const response: Response = await fetch(endpoint, {
        method: 'GET',
        headers: headers
    }).catch((e) => {
        console.error('Error making API request: ' + e.message || e);
        throw new Error('Error making API request');
    });

    if (!response.ok) {
        console.error('Response from API was not ok (not a 2xx)');
        throw new Error('Response from API was not ok (not a 2xx)');
    }

    const text: string = await response.text();

    if (text == null) {
        console.error('Response from API was null');
        throw new Error('Response from API was null');
    }
    return text;
}

const run = async <T extends BaseResponse>(endpoint: string, data?: any, options?: ApiOptions): Promise<T | undefined> => {

    const headers: Headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    let authRequired: boolean = true;
    let authToken: string = '';
    let extraTimeout: boolean = false;
    let background: boolean = false;
    let loadingMessage: string = '';
    let responseType: string = 'text';

    if (options != null) {
        if (options.noAuthRequired != null) authRequired = !options.noAuthRequired;
        if (options.specificAuthToken != null) authToken = options.specificAuthToken;
        if (options.extraTimeout != null) extraTimeout = options.extraTimeout;
    }

    if (authRequired) {
        if (!authToken) {
            if (!Constants.manifest?.extra?.jwt) {
                const e = 'Authorised API call with no auth token supplied';
                console.error(e);
                throw new Error(e);
            } else {
                authToken = Constants.manifest?.extra?.jwt;
            }
        }
        headers.append('Bearer ', authToken);
    }

    if (extraTimeout) {
        const time: number = 15 * 60 * 1000;
        headers.append('timeout', time.toString());
    }

    if (data == null) data = {};

    // Handle base
    data.branding = Constants.manifest?.extra?.branding;
    data.platform = Platform.OS;
    data.version = Constants.manifest?.extra?.version;

    try {
        const response: Response = await runApiCall<T>(endpoint, data, headers);

        return handleApiResponse(response);
    } catch (e) {
        throw e;
    }
}

const runApiCall = async <T extends BaseResponse>(endpoint: string, data: any, headers: Headers): Promise<Response> => {

    let response: Response;
    response = await fetch(endpoint, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    }).catch((e) => {
        console.error('Error making API request: ' + e.message || e);
        throw new Error('Error making API request');
    });

    console.log('API Response: [' + endpoint + ']');
    return response;
};

const handleApiResponse = async <T extends BaseResponse>(apiResponse: Response): Promise<T | undefined> => {
    if (!apiResponse.ok) {
        console.error('Response from API was not ok (not a 2xx)');
        throw new Error('Response from API was not ok (not a 2xx)');
    }

    const data: T = await apiResponse.json();

    if (data == null) {
        console.error('Response from API was null');
        throw new Error('Response from API was null');
    }
    if (data.code != null) {
        console.error('API Error Code: [' + data.code + ']');
        //TODO change the way the error codes are handled, will be directly from the backend, so no need to parse them.
        if (data.code >= 6000 && data.code < 7000) {
            throw new Error(`${data.message} (System Reference: ${data.code})`);
        } else {
            throw new Error(`${data.message} (System Reference: ${data.code})`);
        }
    } else if (data.message != null) {
        //save message so it can be shown later
        const extra = Constants.manifest?.extra;
        extra!.message = data.message;
    }
    return data;
}

export const apiService = {
    doPost, doGetTextResponse
};
