import {SafeAreaProvider} from 'react-native-safe-area-context';
import useCachedResources from './src/hooks/useCachedResources';
import useColorScheme from './src/hooks/useColorScheme';
import React from "react";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {Router} from "./src/routes/Router";
import {MessageProvider} from "./src/providers/MessageProvider";
import {Message} from "./src/components/Message";
import {LoaderProvider} from "./src/providers/LoaderProvider";
import {ActivityLoader} from "./src/components/ActivityLoader";

export default function App() {
    const isLoadingComplete = useCachedResources();
    // Remove comment to test biometrics biometricServices.initialise();

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <SafeAreaProvider>
                <LoaderProvider>
                    <MessageProvider>
                        <Router/>
                        <Message/>
                        <ActivityLoader/>
                    </MessageProvider>
                </LoaderProvider>
            </SafeAreaProvider>
        );
    }
}
