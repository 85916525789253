import {navigationService} from "../../services/NavigationService";

export function getRedirectionUrl (): string {
    let url = "";
    if(navigationService.isPrelive()) {
        url = ".pcn.prelive.connectcashlessparking.com"
    } else {
        url = ".pcn.connectcashlessparking.com"
    }
    return url;
}