import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React, {ReactElement} from 'react';
import Home from "../screens/home/Home";
import LookupResult from "../screens/lookup/LookupResult";
import DomainSelection from "../screens/domain/DomainSelection";
import {navigationService} from "../services/NavigationService";

export type AppStackParams = {
    Home: undefined;
    LookupResult: undefined;
    Payment: undefined;
    DomainSelection: undefined
};

const Stack = createNativeStackNavigator<AppStackParams>();

export function AppStack(): ReactElement {
    return (
        <Stack.Navigator>
            {navigationService.getCouncilDomain() == 'ccp' ?
                <Stack.Screen name="DomainSelection" component={DomainSelection} options={{headerShown: false}}/> :
                <Stack.Screen name="Home" component={Home} options={{headerShown: false}}/>}
            <Stack.Screen name="LookupResult" component={LookupResult} options={{headerShown: false}}/>
        </Stack.Navigator>
    );
}


