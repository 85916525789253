import React from 'react';
import {StyleSheet} from 'react-native';


export const LookupResultCss = (isLowDimension: boolean) => {
    const styles = StyleSheet.create({
        body: {
            fontFamily: "Lato",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: '#eee'
        },
        rowItemTitle: {
            flexDirection: "row",
            width: isLowDimension ? "90%" : "45%"
        },
        logo: {
            height: "90%",
            width: isLowDimension ? "40vw" : "15vw"
        },
        form___h1: {
            display: "flex",
            fontFamily: "SpaceMono",
            fontSize: isLowDimension ? "6vw" : "3vw",
            fontWeight: 'bold',
            color: 'black',
            marginLeft: "6%"
        },
        form: {
            display: "flex",
            width: isLowDimension ? "90%" : "45%",
            height: isLowDimension ? "48vw" : "23vw",
            paddingTop: "%1",
            paddingRight: "2.5%",
            paddingBottom: "2.5%",
            paddingLeft: "2.5%",
            borderRadius: 3,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 6,
            shadowColor: "#222",
            backgroundColor: '#F7F7F7',
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#E3E3E3'
        },
        paymentSessionError: {
            color: "#db2269",
            fontSize: isLowDimension ? "1.7vw" : "0.8vw",
            marginBottom: "1%"
        },
        resultsView: {
            display: "flex",
            width: "100%",
            height: isLowDimension ? "60%" : "50%",
            alignSelf: "center",
        },
        itemRowView: {
            flexDirection: "row",
        },
        itemTextLabel: {
            fontSize: isLowDimension ? "2.5vw" : "1vw",
            marginTop: "1%",
            width: "30%"
        },
        itemTextValue: {
            fontSize: isLowDimension ? "2.5vw" : "1vw",
            marginTop: "1%",
        },
        centeredView: {
            flex: 1,
            alignItems: 'center',
            marginTop: "2%",
        },
        modalViewExternal: {
            backgroundColor: 'white',
            borderRadius: 20,
            padding: "3%",
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
            width: isLowDimension ? "80vw" : "40vw",
            height: isLowDimension ? "80vw" : "40vw",
        },
        modalViewInternal: {
            backgroundColor: 'white',
            width: "100%",
            height: "100%",
        },
        modalViewInternalScroll: {
            backgroundColor: 'white',
            width: "100%",
            height: "100%",
            overflow: "scroll",
            flexDirection: "row"
        },
        modalText: {
            marginBottom: "1%",
            textAlign: 'center',
        },
        tinyLogo: {
            width: "10%",
            height: "10%",
        },
        button: {
            width: "30%",
            cursor: "pointer",
            marginRight: "0.25em",
            marginTop: "0.5em",
            padding: "2%",
            border: "none",
            borderRadius: 4,
            backgroundColor: "#22223B",
            color: "#fefefe",
        },
        button_text: {
            textAlign: "center",
            fontFamily: "SpaceMono",
            fontSize: isLowDimension ? "2.2vw" : "0.95vw",
            color: "white"
        },
        textStyle: {
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: isLowDimension ? "2.0vw" : "0.8vw"
        },
        buttonRowView: {
            flexDirection: "row"
        },
        viewFooter: {
            width: isLowDimension ? "90%" : "45%",
            marginTop: "2%",
            justifyContent: 'space-between',
            flexDirection: "row",
        },
        footerText: {
            color: 'gray',
            fontSize: isLowDimension ? "2vw" : "0.7vw",
            marginLeft: "1%"
        },
        viewFooterMenu: {
            justifyContent: 'space-between',
            flexDirection: "row"
        },
        imagesLookUpError: {
            color: "#db2269",
            fontSize: isLowDimension ? "1.7vw" : "0.8vw",
            marginBottom: "1%"
        },
        imageSourceSingle: {
            width: isLowDimension ? "70vw" :"30vw",
            height: isLowDimension ? "70vw" :"30vw",
            borderStyle: "solid",
            borderColor: "black",
            borderWidth: 0.1
        },
        imageSourceMultiple: {
            width: isLowDimension ? "70vw" : "30vw",
            height: isLowDimension ? "70vw" :"30vw",
            borderStyle: "solid",
            borderColor: "black",
            borderWidth: 0.1,
            marginRight: "1vw"
        },
        buttonInsideModal: {
            width: isLowDimension ? "25%" : "20%",
            cursor: "pointer",
            marginRight: "0.25em",
            marginTop: "0.5em",
            padding: isLowDimension ? "2%" : "1%",
            border: "none",
            borderRadius: 4,
            backgroundColor: "#22223B",
            color: "#fefefe",
        },
        loadingIndicator: {
            position: "absolute",
            height: "100%",
            width: "100%",
            zIndex: 1,
            opacity: 0.5,
            backgroundColor: 'black',
        }
    });

    return (
        styles
    );
};
