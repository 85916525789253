import React, {useEffect, useState} from "react";
import {  Modal, StyleSheet, Text, Pressable, View } from "react-native";
import window from "../constants/Layout";
import {useMessage} from "../hooks/useMessage";


export const Message = () => {
    const {messageConfig} = useMessage();
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        if (messageConfig.visible== true){
            setModalVisible(messageConfig.visible);
        }
    }, [messageConfig]);

    return (
            <Modal
                animationType="fade"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => {
                    setModalVisible(false);
                    messageConfig.visible=false;
                }}
            >
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <View style={[styles.modalTitle]}>
                            <Text style={styles.modalText}>
                                {messageConfig.titleText ? messageConfig.titleText : "tittle"}
                            </Text>
                        </View>
                        <View style={[styles.modalMid]}>
                            <Text style={styles.modalText}>
                                {messageConfig.message ? messageConfig.message : "message"}
                            </Text>
                        </View>
                        <View style={[styles.modalBottom]}>
                            <Pressable
                            testID='hideButton'
                            style={[styles.button, styles.buttonClose]}
                            onPress={() => {
                                setModalVisible(!modalVisible);
                                messageConfig.visible=false;
                            }}
                            >
                            <Text style={styles.textStyle}>
                                {messageConfig.buttonText ? messageConfig.buttonText : "buttonText"}
                            </Text>
                        </Pressable>
                        </View>
                    </View>
                </View>
            </Modal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    modalTitle: {
        width: window.messageSize,
        borderBottomWidth:1,
        borderColor:"#000",
        backgroundColor: "white",
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom:15,
        alignItems: "center",
    },
    modalMid: {
        width: window.messageSize,
        backgroundColor: "white",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop:15,
        paddingBottom:15,
        alignItems: "center",
    },
    modalBottom: {
        width: window.messageSize,
        borderTopWidth:1,
        borderColor:"#000",
        backgroundColor: "white",
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop:15,
        alignItems: "center",
    },
    modalView: {
        width: window.messageSize,
        maxHeight:250,
        margin: 20,
        backgroundColor: "white",
        borderRadius: 10,
        paddingBottom: 15,
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    button: {
        borderRadius: 5,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        width: window.messageSize-40,
        textAlign: "center"
    }
});
