export default {
    fieldValidation: {
        pcnMin: 'Minimum of 10 numbers',
        pcnMax: 'Maximum of 16 numbers',
        onlyNumbers: 'Only Numbers',
        pcnReq: 'PCN Number is required',
    },
    home: {
        pcn: "PCN Number",
        lookup: "Submit",
        title: "PCN Payments",
        vrm: "VRM",
    },
    items: {
        stepOneMessage: "Vehicle registration numbers (VRM) and penalty charge notices (PCN) must be" +
            " entered in the correct format. Please check your VRM and PCN carefully before" +
            " entry. The most common mistake made by vehicles owners which result in an error" +
            " message, is that letters such as \"O\" and \"I\" are entered as the numbers zero [0]" +
            " or one [1] or vice versa.",
    }
}
