import {createContext, useState} from "react";
import * as React from "react";


type LoaderConfigType = {visible: boolean};

type LoaderContextType = {
    loaderConfig:LoaderConfigType,
    showLoader: () => void;
    hideLoader: ()=> void ;
};

export const LoaderContext = createContext<LoaderContextType | null>(null);

export const LoaderProvider: React.FC = ({ children }) => {

    const initialConfig : LoaderConfigType = {
        visible: false
    }
    const [loaderConfig, setLoaderConfig] = useState<LoaderConfigType>(initialConfig);

    function  showLoader  (){
        const visible : boolean = true;
        setLoaderConfig({visible})
    }

    function  hideLoader  (){
        const visible : boolean = false;
        setLoaderConfig({visible})
    }

    return (
      <LoaderContext.Provider value={{loaderConfig ,showLoader,hideLoader }}  >
          {children}
      </LoaderContext.Provider>
    );
}
