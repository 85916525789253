import React, {useEffect, useState} from 'react';
import {LookupResultCss} from "./LookupResultCss";
import {ActivityIndicator, Dimensions, Image, Modal, Pressable, Text, TouchableOpacity, View} from 'react-native';
import {LookupResponse} from "../../models/responses/LookupResponse";
import i18n from "../../services/i18n";
import {doImagesLookup, formatDateForDisplay, payPCN} from "./LookupResultFunctions";
import {navigationService} from "../../services/NavigationService";

const LookupResultView = (props: any) => {

    let isLowDimension = Dimensions.get('window').width < 500;
    const styles = LookupResultCss(isLowDimension);
    const [modalVisible, setModalVisible] = useState(false);
    const response: LookupResponse = props.response;
    const i18title = i18n.t("items.title");
    const i18stepTwoLabel = i18n.t("items.stepTwoLabel");
    const i18pcn = i18n.t("items.pcn");
    const i18vrmNumber = i18n.t("items.vrmNumber");
    const i18feeDueLabel = i18n.t("items.feeDueLabel");
    const i18dateAndTimeLabel = i18n.t("items.dateAndTimeLabel");
    const i18streetLabel = i18n.t("items.streetLabel");
    const i18closeImagesLabel = i18n.t("items.closeImagesLabel");
    const i18backLabel = i18n.t("items.backLabel");
    const i18viewImagesLabel = i18n.t("items.viewImagesLabel");
    const i18proceedToPaymentLabel = i18n.t("items.proceedToPaymentLabel");
    const i18homeLabel = i18n.t("items.homeLabel");
    const i18copyRightText = i18n.t("items.copyRightText");
    const i18brandSelectionPageLabel = i18n.t("items.brandSelectionPageLabel");
    const myDomain = navigationService.getCouncilDomain();
    const [paymentSessionError, setPaymentSessionError] = useState('');
    const [imagesLookupError, setImagesLookUpError] = useState('');
    const [arrayOfImages, setArrayOfImages] = useState<any>([]);
    const [imageList, setImageList] = useState<any>([]);
    const [hasImages, setHasImages] = useState<any>(false);
    const [isLoading, setIsLoading] = useState<any>(false);
    const [disableProceedPayment, setDisableProceedPayment] = useState<any>(false);

    useEffect(() => {
        if (arrayOfImages.length == 1) {
            imageList.push(
                <TouchableOpacity onPress={() => {
                    window.open(arrayOfImages[0], "_blank")
                }}>
                    <Image
                        style={styles.imageSourceSingle}
                        source={{
                            uri: arrayOfImages[0],
                        }}
                    />
                </TouchableOpacity>
            )
            setModalVisible(true);
        }
        if (arrayOfImages.length > 1) {
            for (let i = 0; i < arrayOfImages.length; i++) {
                imageList.push(
                    <TouchableOpacity key={i} onPress={() => {
                        window.open(arrayOfImages[i], "_blank")
                    }}>
                        <Image
                            style={styles.imageSourceMultiple}
                            source={{
                                uri: arrayOfImages[i],
                            }}
                        />
                    </TouchableOpacity>
                )
            }
            setModalVisible(true);
        }
        setImageList(imageList);
    }, [arrayOfImages]);

    return (
        <View style={styles.body}>
            {isLoading && <ActivityIndicator size="large" style={styles.loadingIndicator}></ActivityIndicator>}
            <View style={styles.rowItemTitle}>
                <TouchableOpacity onPress={() => {
                    props.navigation.navigate("Home")
                }}>
                    <Image style={styles.logo}
                           source={require('../../assets/images/' + myDomain + '/logo.png')}
                           resizeMode="center"
                    />
                </TouchableOpacity>
                <h1 style={styles.form___h1}>{i18title}</h1>
            </View>
            <View style={styles.form}>
                <View style={{marginBottom: isLowDimension ? "1%" : "5%", marginTop: isLowDimension ? "1%" : "2%"}}>
                    <Text style={{fontSize: isLowDimension ? "3vw" : "1.7vw"}}>
                        {i18stepTwoLabel}
                    </Text>
                </View>
                <View style={styles.resultsView}>
                    <View style={styles.itemRowView}>
                        <Text style={styles.itemTextLabel}>
                            {i18pcn}:
                        </Text>
                        <Text style={styles.itemTextValue}>
                            {response.ticket}
                        </Text>
                    </View>
                    <View style={styles.itemRowView}>
                        <Text style={styles.itemTextLabel}>
                            {i18vrmNumber}:
                        </Text>
                        <Text style={styles.itemTextValue}>
                            {response.vrm.vrm}
                        </Text>
                    </View>
                    <View style={styles.itemRowView}>
                        <Text style={styles.itemTextLabel}>
                            {i18feeDueLabel}:
                        </Text>
                        <Text style={styles.itemTextValue}>
                            {response.amount / 100}
                        </Text>
                    </View>
                    <View style={styles.itemRowView}>
                        <Text style={styles.itemTextLabel}>
                            {i18dateAndTimeLabel}:
                        </Text>
                        <Text style={styles.itemTextValue}>
                            {formatDateForDisplay(response.occured.toString())}
                        </Text>
                    </View>
                    <View style={styles.itemRowView}>
                        <Text style={styles.itemTextLabel}>
                            {i18streetLabel}:
                        </Text>
                        <Text style={styles.itemTextValue}>
                            {response.location}
                        </Text>
                    </View>
                </View>

                <View>
                    <Modal
                        animationType="fade"
                        transparent={true}
                        visible={modalVisible}
                        onRequestClose={() => {
                            setModalVisible(!modalVisible);
                        }}>
                        <View style={styles.centeredView}>
                            <View style={styles.modalViewExternal}>
                                {imageList.length == 1 &&
                                    <View style={styles.modalViewInternal}>
                                        {imageList}
                                    </View>
                                }
                                {imageList.length > 1 &&
                                    <View style={styles.modalViewInternalScroll}>
                                        {imageList}
                                    </View>
                                }
                            </View>
                            <Pressable
                                style={styles.buttonInsideModal}
                                onPress={() => setModalVisible(!modalVisible)}>
                                <Text style={styles.textStyle}>{i18closeImagesLabel}</Text>
                            </Pressable>
                        </View>
                    </Modal>
                    <View style={styles.buttonRowView}>
                        <Pressable style={styles.button}
                                   onPress={() => props.navigation.navigate("Home")}>
                            <Text style={styles.button_text}>{i18backLabel}</Text>
                        </Pressable>
                        {response.imageNum > 0 &&
                            <Pressable style={styles.button}
                                       onPress={() => {
                                           setIsLoading(true);
                                           setPaymentSessionError('');
                                           if (!hasImages) {
                                               doImagesLookup(response, props.navigation, myDomain, setImagesLookUpError, setHasImages, setArrayOfImages, setIsLoading);
                                           } else {
                                               setModalVisible(true);
                                               setIsLoading(false);
                                               setImagesLookUpError('');
                                           }
                                       }
                                       }>
                                <Text style={styles.button_text}>{i18viewImagesLabel}</Text>
                            </Pressable>
                        }
                        <Pressable disabled={disableProceedPayment} style={styles.button} onPress={() => {
                            setPaymentSessionError('');
                            setImagesLookUpError('');
                            setDisableProceedPayment(true);
                            payPCN(props.navigation, response.ticket, response.vrm.vrm,
                                response.amount, myDomain, setPaymentSessionError, setDisableProceedPayment)
                        }}>
                            <Text style={styles.button_text}>{i18proceedToPaymentLabel}</Text>
                        </Pressable>
                    </View>
                    <Text style={styles.imagesLookUpError}>
                        {imagesLookupError ? imagesLookupError : ''}
                    </Text>
                    <Text style={styles.paymentSessionError}>
                        {paymentSessionError ? paymentSessionError : ''}
                    </Text>
                </View>
            </View>
            <View style={styles.viewFooter}>
                <Text style={styles.footerText}>{i18copyRightText}</Text>
                <View style={styles.viewFooterMenu}>
                    <TouchableOpacity style={{width: isLowDimension ? "10vw" : "3vw"}} onPress={() => {
                        props.navigation.navigate("Home")
                    }}>
                        <Text style={styles.footerText}>
                            {i18homeLabel}
                        </Text>
                    </TouchableOpacity>
                    {myDomain == 'ccp' &&
                        <TouchableOpacity onPress={() => {
                            props.navigation.navigate("DomainSelection")
                        }}>
                            <Text style={styles.footerText}>
                                {i18brandSelectionPageLabel}
                            </Text>
                        </TouchableOpacity>
                    }
                </View>
            </View>
        </View>
    );
};

export default LookupResultView;
