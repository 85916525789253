import {LookupResponse} from "../models/responses/LookupResponse";
import {LookupRequest} from "../models/requests/LookupRequest";
import {apiService} from "./ApiService";
import Constants from "expo-constants";
import {ImagesLookupResponse} from "../models/responses/ImagesLookupResponse";

const lookup = async (pcn: string, vrm: string, client: string): Promise<LookupResponse | undefined> => {
    const request: LookupRequest = {
        pcn,
        client,
        vrm
    };
    return await apiService
        .doPost(Constants.manifest?.extra?.pcnApiUrl + '/web/lookup', request, {noAuthRequired: true});
};

const lookupImages = async (pcn: string, vrm: string, client: string): Promise<ImagesLookupResponse | undefined> => {
    const request: LookupRequest = {
        pcn,
        client,
        vrm
    };
    return await apiService
        .doPost(Constants.manifest?.extra?.pcnApiUrl + '/web/images', request, {noAuthRequired: true});
};

export const lookupService = {
    lookup, lookupImages
};
