export default {
    fieldValidation: {
        pcnMin: 'Minimum of 10 numbers',
        pcnMax: 'Maximum of 16 numbers',
        onlyNumbers: 'Only Numbers',
        pcnReq: 'PCN Number is required',
    },
    home: {
        pcn: "PCN Number",
        lookup: "Submit",
        title: "PCN Payments",
        vrm: "VRM",
    }
}
