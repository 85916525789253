import {AppStack} from './AppStack';
import {NavigationContainer} from '@react-navigation/native';

export const Router = () => {

    return (
        <NavigationContainer>
            <AppStack/>
        </NavigationContainer>
    );
};
