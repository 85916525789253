export default {
    fieldValidation: {
        pcnMin: 'Minimum of 10 numbers',
        pcnMax: 'Maximum of 16 numbers',
        onlyNumbers: 'Only Numbers',
        pcnReq: 'PCN Number is required',
        vrmReq: 'VRM is required',
        vrmInvalid: 'VRM is invalid',
        pcnPrefixError: 'Please check your PCN Number - prefix and suffix',
    },
    items: {
        pcn: "PCN Number",
        lookup: "Submit",
        title: "PCN Payments",
        vrm: "VRM",
        stepOneLabel: "Step 1 of 2 - Enter PCN details",
        stepTwoLabel: "Step 2 of 2 - PCN details",
        stepOneMessage: "Vehicle registration numbers (VRN) and penalty charge notices (PCN) must be" +
            " entered in the correct format. Please check your VRN and PCN carefully before" +
            " entry. The most common mistake made by vehicles owners which result in an error" +
            " message, is that letters such as \"O\" and \"I\" are entered as the numbers zero [0]" +
            " or one [1] or vice versa.",
        copyRightText: "All rights reserved - (C) 2022",
        homeLabel: "Home",
        brandSelectionPageLabel: "Council Selection",
        vrmNumber: "VRM Number",
        feeDueLabel: "Fee Due",
        dateAndTimeLabel: "Date and Time",
        streetLabel: "Street",
        closeImagesLabel: "Close Images",
        backLabel: "Back",
        viewImagesLabel: "View Images",
        proceedToPaymentLabel: "Proceed to Payment"
    }
}
