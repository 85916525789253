import React from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import HomeView from "./HomeView";
import {AppStackParams} from "../../routes/AppStack";

type NavigationProps = NativeStackScreenProps<AppStackParams, 'Home'>;

const Home = ({navigation}: NavigationProps) => {

    return (
        <HomeView route={{key: "Home", name: "Home"}} navigation={navigation}></HomeView>
    );
};

export default Home;
