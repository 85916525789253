import React, {useEffect} from 'react';
import {Dimensions, Image, Text, TouchableOpacity, View} from 'react-native';
import DomainSelectionCss from "./DomainSelectionCss";
import i18n from "../../services/i18n";
import {navigationService} from "../../services/NavigationService";
import {getRedirectionUrl} from "./DomainSelectionFunctions";

const DomainSelectionView = ({navigation}: any) => {

    let isLowDimension = Dimensions.get('window').width < 500;
    const styles = DomainSelectionCss(isLowDimension);
    const i18title = i18n.t("items.title");
    const i18copyRightText = i18n.t("items.copyRightText");
    let itemsList = [];
    let domains = navigationService.getDomainList();
    let redirectionUrl = getRedirectionUrl();

    useEffect(() => {
    }, []);

    for (let i = 0; i < domains.length; i++) {
        itemsList.push(
            <TouchableOpacity style={{margin: isLowDimension ? "1%" : "3%"}} key={i} onPress={() => {
                window.open("https://" + domains[i] + redirectionUrl, '_self')
            }}>
                <Image style={styles.logoSelection}
                       source={require('../../assets/images/' + domains[i] + '/logoMain.png')}
                       resizeMode="center"
                />
            </TouchableOpacity>
        )
    }

    return (

        <View style={styles.body}>
            <View style={styles.rowItemTitle}>
                <Image style={styles.logo}
                       source={require('../../assets/images/ccp/logo.png')}
                       resizeMode="center"
                />
                <h1 style={styles.form___h1}>{i18title}</h1>
            </View>
            <View style={styles.form}>
                <View style={styles.selectionBox}>
                    {itemsList}
                </View>
            </View>
            <View style={styles.viewFooter}>
                <Text style={styles.footerText}>{i18copyRightText}</Text>
            </View>
        </View>
    );
};

export default DomainSelectionView;

