import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { Platform } from 'react-native';
import english from '../../languages/en/common.js';
import englishHounslow from '../../languages/en/hounslow.js';
import englishKnowsley from '../../languages/en/knowsley.js';
import englishLambeth from '../../languages/en/lambeth.js';
import englishOldham from '../../languages/en/oldham.js';

import {navigationService} from "./NavigationService";

// Will crash if not in a browser
if (Platform.OS === 'web') {
    i18n.use(LanguageDetector);
}

i18n
    .use(initReactI18next)
    .init({
        ns: navigationService.getDomainLanguageList(),
        debug: false,
        fallbackLng: 'en',
        defaultNS: navigationService.getCouncilDomain(),
        fallbackNS: 'common',
        compatibilityJSON: 'v3',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                common: english,
                hounslow : englishHounslow,
                knowsley: englishKnowsley,
                lambeth: englishLambeth,
                oldham: englishOldham
            },
        }
    });

export default i18n;
