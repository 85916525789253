import React from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import DomainSelectionView from "./DomainSelectionView";
import {AppStackParams} from "../../routes/AppStack";

type NavigationProps = NativeStackScreenProps<AppStackParams, 'DomainSelection'>;

const DomainSelection = ({navigation}: NavigationProps) => {

    return (
        <DomainSelectionView route={{key: "DomainSelection", name: "DomainSelection"}} navigation={navigation}></DomainSelectionView>
    );
};

export default DomainSelection;
