import React, {useEffect, useState} from "react";
import {Alert, Modal, StyleSheet, Text, Pressable, View, ActivityIndicator} from "react-native";
import {useLoader} from "../hooks/useLoader";



export const ActivityLoader= () => {
    const {loaderConfig} =  useLoader();
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
            setModalVisible(loaderConfig.visible);
    }, [loaderConfig]);

    return (
        <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
                setModalVisible(false);
            }}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={styles.viewIndicator}>
                        <ActivityIndicator />
                    </View>
                    <View style={styles.viewText}>
                        <Text style={styles.modalText}>
                            Please Wait ...
                        </Text>
                    </View>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 22
    },
    modalView: {
        flexDirection: "row",
        width: 150,
        maxHeight:250,
        margin: 20,
        backgroundColor: "white",
        borderRadius: 10,
        paddingBottom: 15,
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20,
        alignItems: "flex-start",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    viewIndicator:{
        paddingRight:5
    },
    viewText:{
        paddingLeft:5,
        paddingRight:5
    },
    modalText: {
        textAlign: "center"
    }
});

