import React from 'react';
import {StyleSheet} from 'react-native';


const HomeCss = (isLowDimension: boolean) => {

    const styles = StyleSheet.create({
        body: {
            fontFamily: "Lato",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: '#eee'
        },
        rowItemTitle: {
            flexDirection: "row",
            width: isLowDimension ? "90%" : "45%"
        },
        logo: {
            height: "90%",
            width: isLowDimension ? "40vw" : "15vw"
        },
        form___h1: {
            display: "flex",
            fontFamily: "SpaceMono",
            fontSize: isLowDimension ? "6vw" : "3vw",
            fontWeight: 'bold',
            color: 'black',
            marginLeft: "6%"
        },
        form: {
            display: "flex",
            width: isLowDimension ? "90%" : "45%",
            height: isLowDimension ? "48vw" : "22vw",
            paddingTop: "1%",
            paddingRight: "2.5%",
            paddingBottom: "2.5%",
            paddingLeft: "2.5%",
            borderRadius: 3,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 6,
            shadowColor: "#222",
            backgroundColor: '#F7F7F7',
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#E3E3E3'
        },
        stepsText: {
            fontSize: isLowDimension ? "2.2vw" : "0.8vw"
        },
        rowItemError: {
            flexDirection: "row",
            width: "100%"
        },
        rowItem: {
            flexDirection: "row",
            justifyContent: "space-between",
            width: isLowDimension ? "60%" : "40%",
            alignItems: "center"
        },
        label: {
            marginBottom: "0.5em",
            color: "#444",
            fontWeight: "300",
            // alignSelf: "center"
            fontSize: isLowDimension ? "2vw" : "0.85vw"
        },
        input: {
            padding: "3%",
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: "#d6d1d5",
            marginTop: "2%",
            marginBottom: "2%",
            fontSize: isLowDimension ? "2vw" : "0.9vw",
            width: "55%"
        },
        error: {
            color: "#db2269",
            fontSize: isLowDimension ? "1.6vw" : "0.8vw",
            marginBottom: "1%",
            marginLeft: "2%",
            alignSelf: "center"
        },
        lookUpError: {
            color: "#db2269",
            fontSize: isLowDimension ? "1.6vw" : "0.8vw",
            marginBottom: "1%"
        },
        button: {
            width: isLowDimension ? "60%" : "40%",
            cursor: "pointer",
            marginRight: "0.25em",
            marginTop: "0.5em",
            padding: "2%",
            border: "none",
            borderRadius: 4,
            backgroundColor: "#22223B",
            color: "#fefefe"
        },
        button_hover: {
            width: isLowDimension ? "60%" : "40%",
            cursor: "pointer",
            marginRight: "0.25em",
            marginTop: "0.5em",
            padding: "2%",
            border: "none",
            borderRadius: 4,
            backgroundColor: "lightgrey",
            color: "#fefefe"
        },
        button_text: {
            textAlign: "center",
            fontFamily: "SpaceMono",
            fontSize: isLowDimension ? "2.2vw" : "0.95vw",
            color: "white"
        },
        viewFooter: {
            width: isLowDimension ? "90%" : "45%",
            marginTop: "2%",
            justifyContent: 'space-between',
            flexDirection: "row",
        },
        footerText: {
            color: 'gray',
            fontSize: isLowDimension ? "2vw" : "0.7vw",
            marginLeft: "1%"
        },
        viewFooterMenu: {
            justifyContent: 'space-between',
            flexDirection: "row"
        },
        loadingIndicator: {
                position: "absolute",
                height: "100%",
                width: "100%",
                zIndex: 1,
                opacity: 0.5,
                backgroundColor: 'black',
        }
    });
    return (
        styles
    );
};

export default HomeCss;

