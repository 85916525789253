import * as React from "react";
import {createContext, useState} from "react";

type MessageConfigType = {visible: boolean,message:string; duration:number,titleText:string,buttonText:string};

type MessageContextType = {
    messageConfig: MessageConfigType ;
    showMessage : (message: string,titleText:string,buttonText?:string, duration?: number) => void;
}

export const MessageContext = createContext<MessageContextType | null>(null);

export const MessageProvider: React.FC = ({ children }) => {

    const initialConfig:MessageConfigType  = {
        visible: false,
        message: 'message',
        duration: 150000,
        titleText: "Title",
        buttonText: "OK"
    };

    const [messageConfig,setMessageConfig] = useState<MessageConfigType>(initialConfig);

    function  showMessage (message: string,titleText:string,buttonText:string ="OK", duration: number=15000){
        const visible :boolean = true;
        setMessageConfig({visible,message,titleText,buttonText,duration})
    }

    return (
      <MessageContext.Provider value={{messageConfig, showMessage}}>
          {children}
      </MessageContext.Provider>
    );
};
