import { Dimensions } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;
const smallWindow= 375;
const toastSize= 360;
const messageSize= 320;

export default {
  toastSize: toastSize,
  messageSize:messageSize,
  window: {
    width,
    height,
  },
  isSmallDevice: width < smallWindow,
};

