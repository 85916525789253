import React, {useEffect, useState} from 'react';
import {ActivityIndicator, Dimensions, Image, Pressable, Text, TextInput, TouchableOpacity, View} from 'react-native';
import HomeCss from "./HomeCss";
import {Formik} from "formik";
import i18n from "../../services/i18n";
import {checkLookupOptions, doLookup, validateLookup} from "./HomeFunctions";
import {navigationService} from "../../services/NavigationService";

const HomeView = ({navigation}: any) => {

    let isLowDimension = Dimensions.get('window').width < 500;
    const styles = HomeCss(isLowDimension);
    const i18title = i18n.t("items.title");
    const i18pcn = i18n.t("items.pcn");
    const i18vrm = i18n.t("items.vrm");
    const i18lookup = i18n.t("items.lookup");
    const i18stepOneLabel = i18n.t("items.stepOneLabel");
    const i18stepOneMessage = i18n.t("items.stepOneMessage");
    const i18copyRightText = i18n.t("items.copyRightText");
    const i18brandSelectionPageLabel = i18n.t("items.brandSelectionPageLabel");
    const myDomain = navigationService.getCouncilDomain();
    const [lookupError, setLookupError] = useState('');
    const [isLoading, setIsLoading] = useState<any>(false);

    useEffect(() => {
        checkLookupOptions();
    }, []);

    return (

        <View style={styles.body}>
            {isLoading && <ActivityIndicator size="large" style={styles.loadingIndicator}></ActivityIndicator>}
            <View style={styles.rowItemTitle}>
                <TouchableOpacity onPress={() => {
                    navigation.navigate("Home")
                }}>
                    <Image style={styles.logo}
                           source={require('../../assets/images/' + myDomain + '/logoMain.png')}
                           resizeMode="center"
                    />
                </TouchableOpacity>
                <h1 style={styles.form___h1}>{i18title}</h1>
            </View>
            <View style={styles.form}>
                <Formik
                    validationSchema={validateLookup}
                    initialValues={{
                        pcn: '',
                        vrm: ''
                    }}
                    onSubmit={
                        values => {
                            setIsLoading(true);
                            setLookupError('');
                            doLookup(values, navigation, myDomain, setLookupError, setIsLoading);
                        }
                    }
                >
                    {({
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          values,
                          errors,
                          touched,
                          isValid,
                      }) => (
                        <>
                            <View>
                                <View style={{marginBottom: isLowDimension ? "1%" : "2%", marginTop: isLowDimension ? "1%" : "2%"}}>
                                    <Text style={{fontSize: isLowDimension ? "3.2vw" : "1.7vw"}}>
                                        {i18stepOneLabel}
                                    </Text>
                                    <Text style={styles.stepsText}>
                                        {i18stepOneMessage}
                                    </Text>
                                    <Text style={styles.lookUpError}>
                                        {lookupError ? lookupError : ''}
                                    </Text>
                                </View>
                                <View style={styles.rowItemError}>
                                    <View style={styles.rowItem}>
                                        <Text style={styles.label}>{i18pcn}*:</Text>
                                        <TextInput
                                            style={styles.input}
                                            keyboardType="default"
                                            placeholderTextColor="black"
                                            onChangeText={handleChange('pcn')}
                                            onBlur={handleBlur('pcn')}
                                            value={values.pcn}
                                        />
                                    </View>
                                    {(errors.pcn && touched.pcn) &&
                                        <Text style={styles.error}>*{errors.pcn}</Text>
                                    }
                                </View>
                                <View style={styles.rowItemError}>
                                    <View style={styles.rowItem}>
                                        <Text style={styles.label}>{i18vrm}*:</Text>
                                        <TextInput
                                            style={styles.input}
                                            keyboardType="default"
                                            placeholderTextColor="black"
                                            onChangeText={handleChange('vrm')}
                                            onBlur={handleBlur('vrm')}
                                            value={values.vrm}
                                        />
                                    </View>
                                    {(errors.vrm && touched.vrm) &&
                                        <Text style={styles.error}>*{errors.vrm}</Text>
                                    }
                                </View>
                                <Pressable style={isValid ? styles.button : styles.button_hover}
                                           onPress={handleSubmit}
                                           disabled={!isValid}
                                ><Text style={styles.button_text}>{i18lookup}</Text>
                                </Pressable>
                            </View>
                        </>
                    )}
                </Formik>
            </View>
            <View style={styles.viewFooter}>
                <Text style={styles.footerText}>{i18copyRightText}</Text>
                {myDomain == 'ccp' &&
                    <View style={styles.viewFooterMenu}>
                        <TouchableOpacity onPress={() => {
                            navigation.navigate("DomainSelection")
                        }}>
                            <Text style={styles.footerText}>
                                {i18brandSelectionPageLabel}
                            </Text>
                        </TouchableOpacity>
                    </View>
                }
            </View>
        </View>
    );
};

export default HomeView;

