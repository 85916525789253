import React from 'react';
import {StyleSheet} from 'react-native';


const DomainSelectionCss = (isLowDimension: boolean) => {

    const styles = StyleSheet.create({
        body: {
            fontFamily: "Lato",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: '#eee'
        },
        rowItemTitle: {
            flexDirection: "row",
            width: isLowDimension ? "90%" : "45%"
        },
        logo: {
            height: "100%",
            width: isLowDimension ? "40vw" : "18vw"
        },
        form___h1: {
            display: "flex",
            fontFamily: "SpaceMono",
            fontSize: isLowDimension ? "6vw" : "3vw",
            fontWeight: 'bold',
            color: 'black',
            marginLeft: "6%"
        },
        form: {
            display: "flex",
            width: isLowDimension ? "90%" : "45%",
            height: isLowDimension ? "44vw" : "22vw",
            paddingTop: "1%",
            paddingRight: "2.5%",
            paddingBottom: "2.5%",
            paddingLeft: "2.5%",
            borderRadius: 3,
            shadowOffset: {width: 0, height: 1},
            shadowRadius: 6,
            shadowColor: "#222",
            backgroundColor: '#F7F7F7',
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: '#E3E3E3'
        },
        viewFooter: {
            width: isLowDimension ? "90%" : "45%",
            marginTop: "2%",
            justifyContent: 'space-between',
            flexDirection: "row",
        },
        footerText: {
            color: 'gray',
            fontSize: isLowDimension ? "2vw" : "0.7vw",
            marginLeft: "1%"
        },
        logoSelection: {
            height: isLowDimension ? "16vw" : "6vw",
            width: isLowDimension ? "36vw" : "16vw"
        },
        selectionBox: {
            width: isLowDimension ? "90vw" : "41.5vw",
            flexDirection: 'row',
            flexWrap: "wrap",
            justifyContent: "space-between"
        },
    });
    return (
        styles
    );
};

export default DomainSelectionCss;

